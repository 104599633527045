import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import Section from "../components/section"

import styles from "./index.module.css"

export default () => <>
    <SEO title="Other" pathname="/other/" />
    <Layout>
        <Section sectionTitle="Miscellanea">
            <div className={styles.narrow}> 
                <p> To publicise Stephen Hawking's last book, I designed some puzzles. You can try some of them <a href="/puzzlesite/index.html">here</a>. There was some media coverage <a href="https://physicsworld.com/a/can-you-solve-the-first-of-10-puzzles-linked-to-stephen-hawkings-new-book/">here</a> and <a href="https://www.sciencefocus.com/space/can-you-solve-these-deviously-difficult-questions-from-stephen-hawking">here</a>.</p>
                <p>Sometimes I publish posts <a href="http://scrambledeggsontoast.github.io/">here</a> (but I haven't in a while).</p>
            </div>
        </Section>
    </Layout>
</>
